import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import {
  AuthErrorResponse,
  AuthResponse, Creds, isInstanceOfErrorResponse, JWTPayload, RefreshAuthResponse, ResetPasswordResponse,
} from '../models/auth.model';
import { environment } from '../../../environments/environment';
import UserCredential = firebase.auth.UserCredential;

declare const heap: any;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  jwtPayload: JWTPayload;
  refreshToken: string;

  constructor(
      private http: HttpClient,
      private router: Router,
      private cookies: CookieService,
      private auth: AngularFireAuth,
      private analytics: AngularFireAnalytics,
  ) {
    this.jwtPayload = UserService.parseToken(cookies.get(environment.JWT_COOKIE));
    this.refreshToken = cookies.get(environment.REFRESH_TOKEN_COOKIE);
  }

  static parseToken(token: string): JWTPayload {
    return !token ? null : JSON.parse(atob(token.split('.')[1]));
  }

  login(creds: Creds): Observable<AuthResponse | AuthErrorResponse> {
    return this.http.post<AuthResponse | AuthErrorResponse>(`${environment.API_URL}/auth/login`, creds)
      .pipe(map((res: AuthResponse | AuthErrorResponse) => {
        if (!isInstanceOfErrorResponse(res)) {
          this.parseAuthResponse(res as AuthResponse);
        }
        return res;
      }));
  }

  loginAnonymously(): void {
    this.auth.signInAnonymously().then((user: UserCredential) => {
      user.user.getIdToken().then((jwt: string) => {
        this.parseAuthResponse({
          displayName: '',
          email: '',
          expiresIn: '',
          idToken: jwt,
          kind: '',
          localId: user.user.uid,
          refreshToken: user.user.refreshToken,
          registered: false,
        });
        heap.identify(user.user.uid);
        this.analytics.setUserId(user.user.uid);
      });
    });
  }

  getUserId(): string {
    return this.jwtPayload?.user_id ?? '';
  }

  register(creds: Creds): Observable<AuthResponse | AuthErrorResponse> {
    return this.http.post<AuthResponse | AuthErrorResponse>(`${environment.API_URL}/auth/register`, creds)
      .pipe(map((res: AuthResponse | AuthErrorResponse) => {
        if (!isInstanceOfErrorResponse(res)) {
          this.parseAuthResponse(res as AuthResponse);
        }
        return res;
      }));
  }

  forgotPassword(recoveryEmail: string): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(`${environment.API_URL}/auth/reset-password`, {
      email: recoveryEmail,
    });
  }

  parseAuthResponse(res: AuthResponse | RefreshAuthResponse): void {
    this.jwtPayload = UserService.parseToken(res.idToken);
    this.cookies.delete(environment.JWT_COOKIE, '/');
    this.cookies.delete(environment.REFRESH_TOKEN_COOKIE, '/');
    this.cookies.set(
      environment.JWT_COOKIE, res.idToken, { expires: this.jwtPayload.exp, path: '/', domain: environment.BASE_URL },
    );
    this.cookies.set(
      environment.REFRESH_TOKEN_COOKIE, res.refreshToken, { path: '/', domain: environment.BASE_URL },
    );
  }

  // Returns an observable because we will try to refresh the session if JWT is expired
  isLoggedIn(): Observable<boolean> {
    if (this.jwtPayload && (this.jwtPayload.exp * 1000) > Date.now()) {
      return of(true);
    }

    if (!this.refreshToken) {
      return of(false);
    }

    return this.http.post<RefreshAuthResponse | AuthErrorResponse>(`${environment.API_URL}/auth/refresh-session`, {
      refreshToken: this.refreshToken,
    }).pipe(map((res: RefreshAuthResponse | AuthErrorResponse) => {
      if (isInstanceOfErrorResponse(res)) {
        return false;
      }
      this.parseAuthResponse(res);
      return this.jwtPayload && (this.jwtPayload.exp * 1000) > Date.now();
    }));
  }

  logout(): void {
    this.cookies.delete(environment.JWT_COOKIE, '/');
    this.cookies.delete(environment.REFRESH_TOKEN_COOKIE, '/');

    this.jwtPayload = null;
    this.refreshToken = null;
    // TODO: add api endpoint to invalidate jwt payload on python
    this.router.navigate(['/auth/login']);
  }
}
