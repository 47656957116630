import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

// Due to the named routes feature, parenthesis and equal in URLs do not work with the default angular router
// (despite them being safe https://tools.ietf.org/html/rfc3986 in the url). This custom serializer
// encodes parenthesis and equal internally, which allows them to work as expected
export class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  private charsToEncode: Map<string, string> = new Map<string, string>(Object.entries({
    '(': '%28',
    ')': '%29',
    '=': '%3D',
    '+': '%2B',
    '`': '%60',
    '?': '%3F',
    '<': '%3C',
    '>': '%3E',
  }));

  // This function parses the URL before it is saved in Angular's URL tree. It takes non-safe characters (such as parenthesis)
  // and converts them to their encoded equivalents, so Angular is able to properly handle the routing
  parse(url: string): UrlTree {
    const escape = '\\';
    [...this.charsToEncode.keys()].forEach((char: string) => {
      url = url.replace(new RegExp(`${escape}${char}`, 'g'), this.charsToEncode.get(char));
    });

    return this._defaultUrlSerializer.parse(url);
  }

  // This function flips the charsToEncode map (so we can maintain O(1) lookup) and converts the encoded chars in the URL
  // back into decoded chars so they are readable and present a prettier URL
  serialize(tree: UrlTree): string {
    let url = this._defaultUrlSerializer.serialize(tree);
    const charsToEncodeReversed = new Map<string, string>();
    this.charsToEncode.forEach((value: string, key: string) => {
      charsToEncodeReversed.set(value, key);
    });

    const escape = '\\';
    [...charsToEncodeReversed.keys()].forEach((char: string) => {
      url = url.replace(new RegExp(`${escape}${char}`, 'g'), charsToEncodeReversed.get(char));
    });

    return url;
  }
}
