/* eslint-disable */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthGuard } from "./shared/guards/auth.guard";

const routes: ModuleWithProviders<AppRoutingModule> = RouterModule.forRoot([
  { path: '', loadChildren: () => import('./modules/documentation/documentation.module').then((module) => module.DocumentationModule), canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent },
], { useHash: false, scrollPositionRestoration: 'disabled' });

@NgModule({
  imports: [routes],
  exports: [RouterModule],
})
export class AppRoutingModule { }
