import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  // The path changed source will have events pushed to it before it has any subscribers. A replay subject allows
  // us to ensure that even if it has subscibers after the events are pushed to it, it will still emit the last value
  private pathChangedSource = new ReplaySubject<string>(1);
  private activeSymbolSource = new Subject<string>();
  private navigationEventSource = new Subject<string>();

  pathChanged$ = this.pathChangedSource;
  activeSymbol$ = this.activeSymbolSource.asObservable();
  navigationEvent$ = this.navigationEventSource;

  changePath(path: string): void {
    this.pathChangedSource.next(path);
  }

  updateActiveSymbol(path: string): void {
    this.activeSymbolSource.next(path);
  }

  setNavigationEvent(event: string): void {
    this.navigationEventSource.next(event);
  }
}
