import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const outgoingApiRequest = request.url.includes(environment.API_URL);

    return outgoingApiRequest ? next.handle(request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
      },
    })) : next.handle(request);
  }
}
