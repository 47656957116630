import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
      private title: Title, private meta: Meta, @Inject(DOCUMENT) private document: Document,
  ) { }

  updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  updateDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  // Prerender will read this meta tag and return a 404 status code when crawled by a spider
  set404(toggle404: boolean): void {
    if (toggle404) {
      this.meta.updateTag({ name: 'prerender-status-code', content: '404' });
    } else {
      this.meta.removeTag('name="prerender-status-code"');
    }
  }

  /**
   * Unfortunately the angular Meta API does not have support for canonical URLs. This means we have to manually
   * manipulate the dom so that we can update these on every page - a canonical URL is formed with a link tag, thus the
   * link creation in this code. This code insures the link tag is only ever injected into the DOM once - after it is
   * created for the first time, it will reuse that existing link
   *
   * @param url - The canonical URL that needs to be set/updated
   */
  updateCanonicalUrl(url: string): void {
    // eslint-disable-next-line
    const head = this.document.head;
    let element = this.document.querySelector('link[rel=\'canonical\']');
    if (element === null) {
      element = this.document.createElement('link');
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
