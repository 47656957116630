export interface JWTPayload {
  iss: string,
  aud: string,
  auth_time: number,
  user_id: string,
  sub: string,
  iat: number,
  exp: number,
  email: string,
  email_verified: boolean,
  firebase: FirebaseObject
}

// AuthResponse is based on FireBase model
export interface AuthResponse {
  displayName: string,
  email: string,
  expiresIn: string,
  idToken: string,
  kind: string,
  localId: string,
  refreshToken: string,
  registered: boolean,
}

// Firebase model returned when we refresh a user session
export interface RefreshAuthResponse {
  idToken: string,
  refreshToken: string,
  userId: string
}

// ResetPasswordResponse is based on FireBase model
export interface ResetPasswordResponse {
  email: string,
  kind: string,
}

export interface AuthErrorResponse {
  code: string,
  message: string
}

export interface FirebaseObject {
  identities: {
    email: string[]
  },
  sign_in_provider: string
}

export interface Creds {
  email: string,
  password: string
}

export function isInstanceOfErrorResponse(object: any): object is AuthErrorResponse {
  return 'code' in object && 'message' in object;
}
