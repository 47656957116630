import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOADING_BAR_CONFIG, LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import {
  NavigationEnd, Router, UrlSerializer, Event, Scroll, NavigationStart,
} from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { takeWhile } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiRequestInterceptor } from './shared/interceptors/api-request.interceptor';
import { environment } from '../environments/environment';
import { CustomUrlSerializer } from './custom-url-serializer';
import { CustomViewportScroller } from './custom-viewport-scroller';
import { NavService } from './modules/documentation/nav.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    AngularFireModule.initializeApp({
      apiKey: environment.FB_API_KEY,
      authDomain: environment.FB_AUTH_DOMAIN,
      projectId: environment.FB_PROJECT_ID,
      storageBucket: environment.FB_STORAGE_BUCKET,
      messagingSenderId: environment.FB_MESSAGING_SENDER_ID,
      appId: environment.FB_APP_ID,
      measurementId: environment.FB_MEASUREMENT_ID,
    }),
    AngularFireAnalyticsModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        // ts-lint-ignore
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true },
    { provide: ViewportScroller, useClass: CustomViewportScroller },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 150 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
      private router: Router, private navService: NavService, private scroller: ViewportScroller, private loader: LoadingBarService,
  ) {
    router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        navService.setNavigationEvent('start');
      }

      if (e instanceof NavigationEnd) {
        e = e as NavigationEnd;
        this.navService.changePath(e.url);
      }

      if (e instanceof Scroll) {
        const scrollEvent = e as Scroll;
        if (scrollEvent.position) {
          // backward navigation
          this.navService.navigationEvent$.pipe(takeWhile((val: string) => val !== 'completed', true)).subscribe((val: string) => {
            if (val === 'completed') {
              scroller.scrollToPosition(scrollEvent.position);
            }
          });
        } else if (scrollEvent.anchor) {
          // anchor navigation
          this.navService.navigationEvent$.pipe(takeWhile((val: string) => val !== 'completed', true)).subscribe((val: string) => {
            if (val === 'completed') {
              scroller.scrollToPosition([0, 0]);
            }
          });
        } else {
          // forward navigation
          this.navService.navigationEvent$.pipe(takeWhile((val: string) => val !== 'completed', true)).subscribe((val: string) => {
            if (val === 'completed') {
              scroller.scrollToPosition([0, 0]);
            }
          });
        }
      }
    });
  }
}
