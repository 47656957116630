import 'zone.js/dist/zone-error';

export const environment = {
  production: false,
  API_URL: 'http://localhost:5000',
  BASE_URL: 'localhost',
  SWIFT_TYPE_KEY: 'inHV7gi85sCyPHA-bAze',

  /**
   * Cookies
   */
  JWT_COOKIE: 'ontap_jwt-local',
  REFRESH_TOKEN_COOKIE: 'ontap_refresh-local',
  FEEDBACK_COOKIE: 'ontap_feedback-local',

  /**
   * Firebase configuration
   */
  FB_API_KEY: 'AIzaSyDMJLafhx8CTeE6U0oSYD35HkuZwOxOGvY',
  FB_AUTH_DOMAIN: 'bananadocs-staging-environment.firebaseapp.com',
  FB_PROJECT_ID: 'bananadocs-staging-environment',
  FB_STORAGE_BUCKET: 'bananadocs-staging-environment.appspot.com',
  FB_MESSAGING_SENDER_ID: '415463093041',
  FB_APP_ID: '1:415463093041:web:5031a0db7cbdd0fbf43610',
  FB_MEASUREMENT_ID: 'G-S9YSXEZ8C5',
};
