// @ts-nocheck
/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { MobileBreakpointService } from './shared/services/mobile-breakpoint.service';
import { SeoService } from "./shared/services/seo.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
      private analytics: AngularFireAnalytics,
      private router: Router,
      private mobileBreakpoint: MobileBreakpointService,
      private seoService: SeoService
  ) {
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((x: any) => {
      analytics.logEvent('screen_view', { screen_name: x.url });
    });

      window.onbeforeunload = function() {
      heap.track('pageExited')
    }
  }

  ngOnInit(): void {
    window.heap=window.heap||[],heap.load=function(e,t){
      window.heap.appid=e,window.heap.config=t=t||{};
      var r=document.createElement("script");
      r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
      var a=document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(r,a);
      for(var n=function(e){
        return function(){
          heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}
        },
        p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)
        heap[p[o]]=n(p[o])};

    if (environment.production) {
      (async function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2167924,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        heap.load("3527572065", {
          secureCookie: true
      });
    } else {
      heap.load("2918078976", {
        secureCookie: true
      });
    }
    
    window.onbeforeunload = function() {
      heap.track('pageExited', {arbitraryProp: "string"})
    }

    MobileBreakpointService.events.push((mobile: boolean) => {
      Intercom('update', {
        hide_default_launcher: mobile,
      });
    });
    MobileBreakpointService.runEvents();

    // Manually call the mobile breakpoint resize method once on app init, since window.onResize is not called
    // on first load
    this.mobileBreakpoint.onResize();

    this.seoService.updateCanonicalUrl(`https://${environment.BASE_URL}`)
  }

}
