import { ViewportScroller } from '@angular/common';

// Note: This class is an override of the default BrowserViewportScroller so that it scrolls our custom element instead of the window
export class CustomViewportScroller implements ViewportScroller {
  offset: () => [number, number] = () => [0, 0];

  /**
   * Configures the top offset used when scrolling to an anchor.
   *
   * * When given a number, the service will always use the number.
   * * When given a function, the service will invoke the function every time it restores scroll
   * position.
   */
  setOffset(offset: [number, number]|(() => [number, number])): void {
    if (Array.isArray(offset)) {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      this.offset = () => offset;
    } else {
      this.offset = offset;
    }
  }

  /**
   * Returns the current scroll position.
   */
  getScrollPosition(): [number, number] {
    if (CustomViewportScroller.supportScrollRestoration()) {
      return [document.getElementById('doc-page-container').scrollLeft, document.getElementById('doc-page-container').scrollTop];
    }
    return [0, 0];
  }

  /**
   * Sets the scroll position.
   */
  scrollToPosition(position: [number, number]): void {
    if (CustomViewportScroller.supportScrollRestoration()) {
      document.getElementById('doc-page-container').scrollTo(position[0], position[1]);
    }
  }

  /**
   * Scrolls to the provided anchor.
   */
  scrollToAnchor(anchor: string): void {
    if (CustomViewportScroller.supportScrollRestoration()) {
      const elSelectedById = document.querySelector(`#${anchor}`);
      if (elSelectedById) {
        this.scrollToElement(elSelectedById);
        return;
      }
      const elSelectedByName = document.querySelector(`[name='${anchor}']`);
      if (elSelectedByName) {
        this.scrollToElement(elSelectedByName);
      }
    }
  }

  /**
   * Disables automatic scroll restoration provided by the browser.
   */
  setHistoryScrollRestoration(scrollRestoration: 'auto'|'manual'): void {
    if (CustomViewportScroller.supportScrollRestoration()) {
      const { history } = window;
      if (history && history.scrollRestoration) {
        history.scrollRestoration = scrollRestoration;
      }
    }
  }

  private scrollToElement(el: any): void {
    const rect = el.getBoundingClientRect();
    const left = rect.left + window.pageXOffset;
    const top = rect.top + window.pageYOffset;
    const offset = this.offset();
    document.getElementById('doc-page-container').scrollTo(left - offset[0], top - offset[1]);
  }

  private static supportScrollRestoration(): boolean {
    try {
      return !!document.getElementById('doc-page-container') && !!document.getElementById('doc-page-container').scrollTo;
    } catch (e) {
      return false;
    }
  }
}
