import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MobileBreakpointService {
  static events: ((mobile?: boolean) => void)[] = [];
  debouncer: ReturnType<typeof setTimeout>;

  static readonly runEvents = (): void => {
    const mobile = window.getComputedStyle(document.getElementById('mobile-breakpoint')).display === 'block';

    MobileBreakpointService.events.forEach((event) => {
      event(mobile);
    });
  };

  onResize(): void {
    window.onresize = (): void => {
      clearTimeout(this.debouncer);

      this.debouncer = setTimeout(MobileBreakpointService.runEvents, 500);
    };
  }
}
